import {
  FILES_ALLOWED_TYPES,
  IMAGES_ALLOWED_TYPES,
  MEDIA_ALLOWED_TYPES,
} from "./constants";

export const STORY_POST_MEDIA_ALLOWED_CONFIG = {
  extensions: MEDIA_ALLOWED_TYPES,
  maxFiles: 20,
};

export const STORY_POST_FILE_ALLOWED_CONFIG = {
  extensions: FILES_ALLOWED_TYPES,
  maxFiles: 1,
};

export const SCHOOL_ID_BADGE_ALLOWED_CONFIG = {
  extensions: [".pdf", ...IMAGES_ALLOWED_TYPES],
  maxFiles: 1,
};
