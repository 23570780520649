import { type DragEvent, useCallback, useEffect } from "react";
import uniqBy from "lodash/uniqBy";

export const mergeFileLists = (
  ...lists: (FileList | File[] | null | undefined)[]
): FileList => {
  const dt = new DataTransfer();
  const files = uniqBy(
    lists.filter(Boolean).flatMap((list) => Array.from(list)),
    "name",
  );
  for (const file of files) {
    dt.items.add(file);
  }

  return dt.files;
};

export const partitionFiles = (
  files: File[],
  allowedFileExtensions: string[],
) =>
  files.reduce(
    (acc, file) => {
      if (
        allowedFileExtensions.includes(getExtension(file.name.toLowerCase()))
      ) {
        acc.valid.push(file);
      } else {
        acc.invalid.push(file);
      }

      return acc;
    },
    { valid: [] as File[], invalid: [] as File[] },
  );

const getExtension = (fileName: string | undefined) =>
  fileName ? `.${fileName.split(".").pop()}` : "";
export const preventDefault = (event: DragEvent<HTMLFormElement>) =>
  event.preventDefault();
export const useUpdatedDragStateHandler = (
  newState: "enter" | "leave" | "drop",
  handler: (state: "enter" | "leave" | "drop") => void,
) =>
  useCallback(
    (event: DragEvent<HTMLFormElement>) => {
      event.preventDefault();
      handler(newState);
    },
    [handler, newState],
  );

export const useOnPastedFiles = (callback: (files: File[]) => void) =>
  useEffect(() => {
    const pasteHandler = (event: ClipboardEvent) => {
      const items = event.clipboardData?.items ?? [];
      const files: File[] = [];

      for (const item of items) {
        const file = item.getAsFile();
        if (file) {
          // file.name is readonly, and we rely on filenames being unique
          // this clones the file and appends a timestamp to the filename to allow for multiple pastes
          // (e.g. in MacOS, every pasted file is named "image.png")
          const renamed = new File([file], `${Date.now()}-${file.name}`, {
            type: file.type,
          });
          files.push(renamed);
        }
      }

      if (files.length > 0) {
        callback(files);
      }
    };

    window.addEventListener("paste", pasteHandler);

    return () => window.removeEventListener("paste", pasteHandler);
  }, [callback]);
