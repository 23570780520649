import type { FC, MouseEventHandler } from "react";
import { IconMinus } from "@web-monorepo/dds-icons";
import styles from "./MinusButton.module.css";

type Props = {
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const MinusButton: FC<Props> = ({ className, onClick }) => (
  <button
    className={[styles.minusButton, className].join(" ")}
    onClick={onClick}
  >
    <IconMinus size="s" fill={"dt_content_primary"} />
  </button>
);

export default MinusButton;
