// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconFileDocument = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.37008 17.4225C3.59508 19.08 4.89257 20.4038 6.55007 20.6288C8.35757 20.8763 10.1801 21 12.0026 21C13.8138 21 15.6251 20.8763 17.4251 20.6325C19.0901 20.4038 20.4063 19.0875 20.6313 17.4225C21.1226 13.815 21.1226 10.155 20.6276 6.54751C20.3988 4.89001 19.0788 3.5925 17.4213 3.3675C15.6251 3.12375 14.6538 3 13.4275 3C13.3338 3 13.24 3 13.1425 3.00375C11.8638 3.0225 10.6526 3.55501 9.76009 4.47001C8.26759 6.00001 5.86006 8.46375 4.38631 9.975C3.51631 10.8675 3.02133 12.0563 3.00258 13.3013C2.98383 14.61 3.10758 15.4875 3.37008 17.4225ZM18.775 6.82876C19.2438 10.26 19.2438 13.7438 18.775 17.175C18.6625 18.0075 18.0063 18.6638 17.1738 18.78C15.4675 19.0125 13.7276 19.1325 12.0026 19.1325C10.2776 19.1325 8.53757 19.0125 6.82757 18.78C5.99507 18.6675 5.34256 18.0075 5.23006 17.1788L5.22255 17.115C5.0538 15.8663 4.94877 15.1013 4.90377 14.3738C4.89252 14.1638 5.05009 13.9875 5.26009 13.9763C6.42634 13.9163 7.58876 13.8113 8.74001 13.6538C11.2413 13.3125 13.2138 11.3363 13.5513 8.83501C13.7125 7.65376 13.8213 6.46125 13.8813 5.265C13.8926 5.05875 14.0688 4.89751 14.275 4.90876C15.07 4.95376 15.8875 5.05876 17.1775 5.23501C18.0063 5.34001 18.6625 5.99626 18.775 6.82876ZM11.7176 4.94251C11.8751 4.93126 12.0063 5.06251 11.9951 5.22376C11.9463 5.91376 11.8188 7.62375 11.6913 8.58C11.4626 10.2488 10.15 11.5613 8.48504 11.7863C7.53254 11.9175 5.80751 12.045 5.17376 12.09C5.04626 12.0975 4.94131 11.9925 4.94881 11.865C4.95256 11.8163 4.97128 11.7675 5.00878 11.73L11.545 5.02126C11.59 4.97626 11.6501 4.94626 11.7176 4.94251Z" /></svg>;
};