// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconConversationFilled = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.9901 7.89743C12.1126 7.89743 8.95881 11.0512 8.95881 14.9287V14.9249C8.95881 15.6749 9.08256 16.3987 9.30756 17.0849C9.34506 17.1974 9.27381 17.3174 9.15381 17.3324C6.96006 17.5949 4.74006 17.6212 2.53881 17.3662C2.21256 17.3287 2.11131 16.9124 2.37756 16.7249C3.09381 16.2299 3.60381 15.4874 3.81006 14.6399C3.82506 14.5762 3.81006 14.5087 3.76881 14.4562C2.59881 13.0199 1.95381 11.1449 2.13006 9.11243C2.44131 5.48618 5.47506 2.57618 9.05631 2.35493C12.6376 2.13368 15.7013 4.45118 16.6501 7.67618C16.6876 7.79993 16.5863 7.91993 16.4551 7.91243C16.3013 7.90118 16.1476 7.89743 15.9901 7.89743ZM20.5725 18.9187C20.73 19.5637 21.1763 20.0587 21.6975 20.4675V20.4712C21.8925 20.6212 21.8063 20.94 21.555 20.97C20.5838 21.0825 19.6838 21.1537 18.765 21.1537C17.5763 21.1537 16.35 21.0337 14.8725 20.7262C12.2513 20.1825 10.0875 17.8087 10.0875 14.9287C10.0875 11.5687 12.8925 8.86121 16.2863 9.02621C19.2975 9.1687 21.765 11.6512 21.8963 14.6625C21.96 16.1325 21.4838 17.49 20.655 18.555C20.5725 18.66 20.5425 18.7912 20.5725 18.9187Z" /></svg>;
};