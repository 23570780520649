// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconExpand = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.5562 4.67999L14.1525 4.67623C13.665 4.67248 13.2187 4.31998 13.1737 3.83248C13.1175 3.26998 13.56 2.79749 14.1075 2.79749L18.4725 2.80499C19.2225 2.80874 19.8637 3.34498 19.995 4.08373L20.7487 8.32874C20.8162 8.71499 20.67 9.12749 20.3362 9.33749C20.1787 9.43874 20.0025 9.48374 19.8337 9.48374C19.3875 9.48374 18.99 9.16499 18.9112 8.71124L18.4762 6.26624C18.45 6.10874 18.2512 6.05623 18.15 6.17623L14.97 9.92249C14.7825 10.14 14.52 10.2525 14.2537 10.2525C14.0512 10.2525 13.8487 10.1887 13.6762 10.0537C13.2525 9.72373 13.23 9.07124 13.5787 8.66249L16.6987 4.98748C16.8037 4.86748 16.7175 4.67999 16.5562 4.67999Z" /><path d="M9.88873 21.2025L5.52372 21.195C4.76997 21.195 4.13248 20.655 4.00498 19.92L3.25122 15.675C3.16497 15.195 3.43121 14.6925 3.89996 14.5575C4.43996 14.4 4.98748 14.7487 5.08498 15.2925L5.51997 17.7375C5.54622 17.895 5.74497 17.9475 5.84622 17.8275L9.02621 14.0812C9.38246 13.6612 10.0275 13.635 10.4175 14.04C10.7587 14.3925 10.74 14.9587 10.4212 15.3337L7.29371 19.0162C7.18871 19.1362 7.27496 19.3237 7.43621 19.3237L9.89246 19.3275C10.5337 19.3275 11.025 19.9762 10.7475 20.655C10.6087 20.9962 10.2562 21.2025 9.88873 21.2025Z" /></svg>;
};