// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconVolumnOn = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.7359 4.5996C12.3359 4.3296 13.0334 4.67835 13.1722 5.32335C14.1284 9.7221 14.1284 14.2784 13.1722 18.6771C13.0334 19.3184 12.3359 19.6709 11.7359 19.4009C9.56091 18.5234 7.67466 17.0609 6.27966 15.1746L6.12966 14.9684C6.04716 14.8709 5.91591 14.8259 5.78841 14.8559L5.76591 14.8634C5.04591 15.0546 4.29216 15.0809 3.56091 14.9346C3.22716 14.8671 2.95341 14.6384 2.83341 14.3121L2.82216 14.2859C2.28591 12.8271 2.27466 11.2221 2.79966 9.75585C2.80341 9.7446 2.80716 9.7296 2.81466 9.71835C2.93466 9.38085 3.22341 9.1296 3.59091 9.05835L3.63591 9.05085C4.35216 8.90835 5.09091 8.9421 5.79216 9.1446C5.91966 9.1746 6.05091 9.1296 6.13341 9.0321L6.28341 8.82585C7.67841 6.9396 9.56841 5.4771 11.7397 4.5996H11.7359ZM16.6072 16.7459C17.8747 15.4784 18.5722 13.7909 18.5722 11.9984C18.5722 10.2059 17.8747 8.51835 16.6072 7.25085C16.2397 6.88335 15.6472 6.88335 15.2797 7.25085C14.9122 7.61835 14.9122 8.21085 15.2797 8.57835C16.1947 9.49335 16.6972 10.7084 16.6972 11.9984C16.6972 13.2884 16.1947 14.5071 15.2797 15.4184C14.9122 15.7859 14.9122 16.3784 15.2797 16.7459C15.4634 16.9296 15.7034 17.0196 15.9434 17.0196C16.1834 17.0196 16.4234 16.9296 16.6072 16.7459ZM18.7372 18.8759C20.5747 17.0384 21.5872 14.5971 21.5872 12.0021C21.5872 9.4071 20.5747 6.9621 18.7372 5.12835C18.3697 4.76085 17.7772 4.76085 17.4097 5.12835C17.0422 5.49585 17.0422 6.08835 17.4097 6.45585C18.8909 7.9371 19.7084 9.9096 19.7084 12.0059C19.7084 14.1021 18.8909 16.0746 17.4097 17.5559C17.0422 17.9234 17.0422 18.5159 17.4097 18.8834C17.5934 19.0671 17.8334 19.1571 18.0734 19.1571C18.3134 19.1571 18.5534 19.0671 18.7372 18.8834V18.8759Z" /></svg>;
};