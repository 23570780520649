import type { FC } from "react";
import classnames from "classnames";
import { IconPlus } from "@web-monorepo/dds-icons";
import { useTranslate } from "../i18n";
import { useFiles } from "../state";
import styles from "./Nav.module.css";

/* this is fine because the parent DropZone renders a form */
/* eslint-disable jsx-a11y/label-has-associated-control */

const ADD_MORE = {
  str: "dojo.common:file_upload.add_more",
  fallback: "Add more",
} as const;
const CANCEL = {
  str: "dojo.common:actions.cancel",
  fallback: "Cancel",
} as const;

type Props = {
  maxAllowedFiles: number;
  fileInputName: string;
  onCancel?(): void;
};

const Nav: FC<Props> = ({ fileInputName, maxAllowedFiles, onCancel }) => {
  const [files] = useFiles();
  const translate = useTranslate();
  return (
    <nav className={classnames(styles.nav, { [styles.noCancel]: !onCancel })}>
      {onCancel && (
        <div className={styles.cancel}>
          <button className={styles.cancelButton} onClick={onCancel}>
            <span className={styles.cancelText}>{translate(CANCEL)}</span>
          </button>
        </div>
      )}
      {maxAllowedFiles > 1 && (
        <>
          <span
            className={styles.fileCount}
          >{`${files.length} / ${maxAllowedFiles}`}</span>
          <label
            data-name="file_upload.dropzone.plus_button"
            className={classnames(styles.addMore, {
              [styles.addMoreDisabled]: files.length >= maxAllowedFiles,
            })}
            htmlFor={fileInputName}
          >
            <IconPlus size="s" aria-label={translate(ADD_MORE)} />
          </label>
        </>
      )}
    </nav>
  );
};

export default Nav;
