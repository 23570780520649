// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMail = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.8724 12.5349C11.9624 12.5761 12.0411 12.5761 12.1086 12.5461C15.2999 11.1324 18.2587 9.30238 20.9362 7.10863C21.0749 6.99238 21.1461 6.80864 21.1086 6.63239V6.62864L21.0712 6.48989C20.9962 6.21989 20.7487 6.02863 20.4674 6.02863C14.8462 5.31988 9.15745 5.31988 3.53995 6.02863C3.2587 6.02863 3.01116 6.21614 2.93616 6.48989L2.89862 6.62864V6.63239C2.86487 6.80864 2.93245 6.99238 3.0712 7.10863C5.7487 9.30238 8.70369 11.1324 11.8724 12.5349Z" /><path d="M12.5887 14.1286C12.3937 14.2149 12.1949 14.2561 11.9962 14.2561C11.8049 14.2561 11.6137 14.2149 11.4374 14.1361C8.36994 12.7749 5.50868 11.0424 2.89493 8.9799C2.77868 8.8899 2.61 8.95739 2.59125 9.10364C2.25 11.8561 2.34743 14.6536 2.89493 17.3799C2.95868 17.6949 3.21751 17.9311 3.53626 17.9686L3.61499 17.9799C9.18374 18.6774 14.8162 18.6774 20.385 17.9799L20.4637 17.9686C20.7825 17.9274 21.0412 17.6911 21.105 17.3799C21.6525 14.6499 21.75 11.8561 21.4087 9.10364C21.39 8.95739 21.2212 8.8899 21.105 8.9799C18.495 11.0461 15.6375 12.7749 12.5887 14.1286Z" /></svg>;
};