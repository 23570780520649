import { atom, useAtom, useSetAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { useAtomCallback } from "jotai/utils";
import uniqBy from "lodash/uniqBy";
import { useCallback, useMemo } from "react";

const dragReferencesAtom = atom(0);
const isDraggingAtom = atom(
  (get) => get(dragReferencesAtom) > 0,
  (get, set, event: "enter" | "leave" | "drop") => {
    switch (event) {
      case "enter":
        return set(dragReferencesAtom, get(dragReferencesAtom) + 1);
      case "leave":
        return set(dragReferencesAtom, get(dragReferencesAtom) - 1);
      case "drop":
        set(dragReferencesAtom, 0);
    }
  },
);

export const useIsDragging = () => useAtom(isDraggingAtom);

export const filesAtom = atom([] as File[]);
const addFilesAtom = atom(
  (get) => get(filesAtom),
  (get, set, files: File[] | null | undefined, maxAllowed: number) => {
    if (files) {
      const merged = uniqBy([...get(filesAtom), ...files], "name");
      set(filesAtom, merged.slice(0, maxAllowed));
    }
  },
);

export const useFiles = () => {
  const [files, setFiles] = useAtom(filesAtom);
  const addFiles = useSetAtom(addFilesAtom);

  return [files, { addFiles, setFiles }] as const;
};

export const useDeleteFile = (file: File | null | undefined) =>
  useAtomCallback(
    useCallback(
      (_, set) =>
        set(filesAtom, (files) => files.filter((f) => f.name !== file?.name)),
      [file?.name],
    ),
  );

export const useOnFilesChange = (callback: (files: File[]) => void) => {
  const effect = useMemo(
    () =>
      atomEffect((get) => {
        callback(get(filesAtom));
      }),
    [callback],
  );

  return useAtom(effect);
};
