// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconCollapse = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.6291 9.12584L19.0328 9.12959C19.5241 9.13334 19.9703 9.48584 20.0191 9.96959C20.0753 10.5321 19.6328 11.0046 19.0853 11.0046L14.7203 10.9971C13.9703 10.9971 13.3291 10.4608 13.1978 9.72208L12.4441 5.47709C12.3578 4.99334 12.6241 4.49084 13.0928 4.35584C13.6328 4.19834 14.1803 4.54709 14.2778 5.09084L14.7128 7.53584C14.7391 7.69334 14.9378 7.74584 15.0391 7.62584L18.2191 3.87959C18.5753 3.45959 19.2204 3.43334 19.6103 3.83834C19.9516 4.19459 19.9329 4.76084 19.6141 5.13584L16.4866 8.81834C16.3816 8.93834 16.4678 9.12584 16.6291 9.12584Z" /><path d="M9.27908 13.0071C10.0291 13.0071 10.6703 13.5433 10.8016 14.2821L11.5591 18.5271C11.6266 18.9133 11.4803 19.3258 11.1466 19.5358C10.9891 19.6371 10.8128 19.6821 10.6441 19.6821C10.1978 19.6821 9.80035 19.3633 9.7216 18.9096L9.28658 16.4646C9.26033 16.3071 9.06158 16.2546 8.96033 16.3746L5.78034 20.1208C5.59284 20.3383 5.33033 20.4508 5.06408 20.4508C4.86158 20.4508 4.65906 20.3871 4.48656 20.2521C4.06281 19.9221 4.04031 19.2696 4.38906 18.8608L7.50909 15.1858C7.61409 15.0658 7.52783 14.8783 7.36658 14.8783L4.91032 14.8746C4.26907 14.8746 3.7778 14.2258 4.05531 13.5471C4.19406 13.2058 4.54657 12.9996 4.91407 12.9996L9.27908 13.0071Z" /></svg>;
};