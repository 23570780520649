import type {
  FileDescription,
  PhotoDescription,
  UploadDescription,
  UploadedFile,
  VideoDescription,
} from "./types";

const cdnUrls: Record<string, string> = {
  file: "https://files.classdojo.com",
  photo: "https://images.classdojo.com/dojophotos",
  video: "https://dojovideos.classdojo.com",
};

export const addMetadata = async (
  result: UploadedFile[],
): Promise<UploadDescription[]> =>
  await Promise.all(result.map(uploadedFileToDescription));

const uploadedFileToDescription = (
  config: UploadedFile,
): Promise<UploadDescription> => {
  const file = config.file;
  const fileExtension = file.name.split(".").pop();

  if (!fileExtension) throw new Error("missing file extension");

  const fileType = file.type.split("/")[0];
  switch (fileType) {
    case "image":
      return processPhoto(config);
    case "video":
      return processVideo(config);
    default:
      return Promise.resolve<FileDescription>({
        ...file,
        type: "file",
        urlSmall: config.urlSmall ?? config.cdnUrl,
        metadata: {
          filename: "name" in file ? file.name : undefined,
          size: file.size,
          mimetype: file.type,
        },
        key: config.key,
        path: config.key,
        fullPath: config.cdnUrl ?? `${cdnUrls.file}/${config.key}`,
      });
  }
};

const processPhoto = (config: UploadedFile) =>
  new Promise<PhotoDescription>((resolve) => {
    const element = new Image();
    const fullPath = config.cdnUrl ?? `${cdnUrls.photo}/${config.key}`;
    element.onload = function () {
      resolve({
        type: "photo",
        key: config.key,
        path: config.key,
        urlSmall: config.urlSmall,
        metadata: {
          width: element.width,
          height: element.height,
          filename: "name" in config.file ? config.file.name : undefined,
        },
        fullPath,
      });
    };

    element.onerror = () => {
      resolve({
        type: "photo",
        key: config.key,
        path: config.key,
        urlSmall: config.urlSmall,
        metadata: {
          filename: "name" in config.file ? config.file.name : undefined,
          width: 0,
          height: 0,
        },
        fullPath: config.cdnUrl,
      });
    };

    element.src = fullPath;
  });

const processVideo = (config: UploadedFile) =>
  new Promise<VideoDescription>((resolve) => {
    const fullPath = config.cdnUrl ?? `${cdnUrls.video}/${config.key}`;
    const element = document.createElement("video");

    element.src = String(fullPath);
    element.addEventListener("loadedmetadata", () => {
      resolve({
        type: "video",
        key: config.key,
        path: config.key,
        urlSmall: config.urlSmall ?? config.cdnUrl,
        metadata: {
          contentType:
            config.file.type === "video/mp4" ? "video/mp4" : "video/webm",
          width: element.videoWidth,
          height: element.videoHeight,
          duration: config.duration ?? element.duration,
        },
        fullPath,
      });
    });
  });
