// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconLockClosed = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.8234 11.595C18.6659 10.8 17.9908 10.215 17.1846 10.1288C17.0008 10.11 16.8584 9.95253 16.8584 9.76878V8.53503C16.8584 5.77503 14.5446 3.54378 11.7546 3.68253C9.14456 3.81003 7.14214 6.06378 7.14214 8.67378V9.76878C7.14214 9.95628 7.00334 10.11 6.81584 10.1288C6.00959 10.2113 5.33464 10.8 5.17714 11.595C4.72714 13.86 4.72338 16.1888 5.16963 18.4538L5.17714 18.4913C5.33089 19.2825 5.9758 19.8863 6.77455 19.9875C10.2433 20.4375 13.7533 20.4375 17.2221 19.9875C18.0208 19.8825 18.6658 19.2825 18.8196 18.4913L18.8271 18.4538C19.2771 16.1888 19.2734 13.86 18.8234 11.595ZM9.40331 9.89628C9.19331 9.91128 9.01714 9.74628 9.01714 9.53628V8.53503C9.01714 6.82128 10.4684 5.44128 12.2046 5.55753C13.7871 5.66253 14.9834 7.05003 14.9834 8.64003V9.53628C14.9834 9.74628 14.8071 9.90753 14.5971 9.89628C12.8683 9.78378 11.1321 9.78378 9.40331 9.89628Z" /></svg>;
};