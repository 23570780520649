import { useEffect, type FC } from "react";
import { TRANSLATOR_CONTEXT, type Translator } from "./i18n";
import { useFiles } from "./state";

import Dropzone, { Props as DropzoneProps } from "./Dropzone";
import Preview from "./Preview";

export type Props = Pick<
  DropzoneProps,
  "onCancel" | "onInvalidFileDrop" | "prompt"
> & {
  allowedFileConfig: { extensions: string[]; maxFiles: number };
  onChange?(files: File[]): void;
  translate: Translator;
};

const FilePicker: FC<Props> = ({
  allowedFileConfig,
  prompt,
  onCancel,
  onChange,
  onInvalidFileDrop,
  translate,
}) => {
  const [files] = useFiles();
  const maxAllowedFiles = allowedFileConfig.maxFiles;
  useEffect(() => onChange?.(files), [files]);

  return (
    <TRANSLATOR_CONTEXT.Provider value={translate}>
      <Dropzone
        allowedFileExtensions={allowedFileConfig.extensions}
        maxAllowedFiles={maxAllowedFiles}
        onCancel={onCancel}
        onInvalidFileDrop={onInvalidFileDrop}
        prompt={prompt}
      >
        <Preview files={files} />
      </Dropzone>
    </TRANSLATOR_CONTEXT.Provider>
  );
};

export default FilePicker;
