// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconCalendar = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.6327 7.64299C20.4265 6.12424 19.3203 4.90549 17.8653 4.52674C17.6965 4.48174 17.569 4.3355 17.569 4.15925V2.91799C17.569 2.42674 17.2165 1.9805 16.729 1.93175C16.1665 1.8755 15.694 2.31424 15.694 2.86549V3.83674C15.694 4.05424 15.5065 4.22674 15.289 4.20799C14.194 4.11799 13.099 4.06924 12.0002 4.06924C10.909 4.06924 9.81397 4.11799 8.72272 4.20799C8.50522 4.22674 8.31778 4.05424 8.31778 3.83674V2.92174C8.31778 2.43049 7.96528 1.98424 7.47778 1.93549C6.91528 1.87924 6.44278 2.318 6.44278 2.86925V4.15925C6.44278 4.3355 6.31901 4.48174 6.14651 4.52674C4.68776 4.90174 3.57396 6.12424 3.36771 7.64674C2.87646 11.2467 2.87646 14.8955 3.36771 18.4955C3.47646 19.2867 3.82524 19.9955 4.34274 20.5505C4.91649 21.1655 5.69647 21.5855 6.57397 21.7055C8.37397 21.9492 10.1852 22.073 11.9965 22.073C13.8077 22.073 15.6228 21.9492 17.419 21.7055C18.2515 21.593 18.9978 21.2067 19.564 20.6405C20.1303 20.0742 20.5127 19.3317 20.629 18.4955C21.124 14.8917 21.124 11.243 20.6327 7.64299ZM18.7765 18.233C18.664 19.0655 18.0077 19.7292 17.1715 19.8417C15.4652 20.0742 13.7252 20.1942 12.0002 20.1942C10.2752 20.1942 8.53529 20.078 6.82904 19.8417C5.99279 19.7292 5.34028 19.0692 5.22403 18.233C4.89403 15.8105 4.80027 13.3617 4.93527 10.9242C4.94652 10.7292 5.10401 10.5755 5.29901 10.568C9.76526 10.3767 14.2353 10.3842 18.7015 10.583C18.8965 10.5905 19.054 10.748 19.0652 10.9392C19.2002 13.373 19.1065 15.8142 18.7765 18.233Z" /></svg>;
};