import type { UploadedUppyFile as BaseUploadedUppyFile } from "@uppy/core";
import type { SetRequired, Simplify } from "type-fest";
import { MediaDescriptionGeneratorError, UppyUploadError } from "../../errors";
import type {
  FileDescription,
  PhotoDescription,
  UploadDescription,
  VideoDescription,
} from "../../types";
import { metricLogger } from "../shared";
import { getUppy } from "./uppy";

type ResultMetadata = {
  key?: string;
  urlSmall?: string;
  cdnUrl?: string;
  uploadId?: string;
  region?: string;
  width?: number;
  height?: number;
  duration?: number;
};

type UploadedUppyFile = Simplify<
  BaseUploadedUppyFile<ResultMetadata, Record<string, unknown>>
>;
type UploadedUppyFileWithKey = Simplify<
  BaseUploadedUppyFile<
    SetRequired<ResultMetadata, "key">,
    Record<string, unknown>
  >
>;

const cdnUrls: Record<string, string> = {
  file: "https://files.classdojo.com",
  photo: "https://images.classdojo.com/dojophotos",
  video: "https://dojovideos.classdojo.com",
};

const LogMetric = metricLogger("uppy");

interface UploadFiles {
  files: File[];
  bucket?: string;
  getBucket?: (file: File) => string;
}
export async function uploadFiles({
  bucket,
  files,
  getBucket,
}: UploadFiles): Promise<UploadDescription[]> {
  try {
    const getBucketName = bucket != null ? () => bucket : getBucket;
    const uppy = await getUppy({ getBucket: getBucketName });

    uppy.addFiles(
      files.map((file) => ({ name: file.name, data: file, type: file.type }))
    );

    const result = await uppy.upload();
    result.successful.forEach(() => LogMetric.success("s3_put"));
    LogMetric.failure("s3_put", result.failed.length);

    if (result.failed.length) {
      throw new UppyUploadError(result.failed[0].error);
    }

    const uploadDescriptors = await Promise.all(
      result.successful.map((uploadResult) =>
        describeUppyFiles(uploadResult, bucket)
      )
    );

    LogMetric.success("transaction");
    return uploadDescriptors;
  } catch (error) {
    LogMetric.failure("transaction");
    throw error;
  }
}

const processPhoto = (file: UploadedUppyFileWithKey) =>
  new Promise<PhotoDescription>((resolve, reject) => {
    const element = new Image();

    const fullPath = file.meta.cdnUrl ?? `${cdnUrls.photo}/${file.meta.key}`;

    element.onload = function () {
      resolve({
        type: "photo",
        key: file.meta.key,
        path: file.meta.key,
        urlSmall: file.meta.urlSmall,
        metadata: {
          width: element.width,
          height: element.height,
          filename: "name" in file.data ? file.data.name : undefined,
        },
        fullPath,
      });
    };

    element.onerror = function (err) {
      reject(new MediaDescriptionGeneratorError(err));
    };

    element.src = fullPath;
  });

const processVideo = (file: UploadedUppyFileWithKey) =>
  new Promise<VideoDescription>((resolve, reject) => {
    const fullPath = file.meta.cdnUrl ?? `${cdnUrls.video}/${file.meta.key}`;
    const element = document.createElement("video");

    element.src = String(fullPath);
    element.addEventListener("loadedmetadata", () => {
      resolve({
        type: "video",
        key: file.meta.key,
        path: file.meta.key,
        urlSmall: file.meta.urlSmall ?? file.meta.cdnUrl,
        metadata: {
          contentType:
            file.data.type === "video/mp4" ? "video/mp4" : "video/webm",
          width: element.videoWidth,
          height: element.videoHeight,
          duration: file.meta.duration,
        },
        fullPath,
      });
    });

    element.onerror = function (err) {
      reject(new MediaDescriptionGeneratorError(err));
    };
  });

const hasKeyInMetadata = (
  file: UploadedUppyFile
): file is UploadedUppyFileWithKey => typeof file.meta.key === "string";

const describeUppyFiles = (
  file: UploadedUppyFile,
  bucket: string | undefined
): Promise<UploadDescription> => {
  if (!hasKeyInMetadata(file)) {
    throw new Error("missing key in metadata");
  }

  const fileType = file.meta.type?.split("/")?.[0];
  const defaultDescriptor = (fullPath: string) => {
    return Promise.resolve<FileDescription>({
      ...file,
      type: "file",
      key: file.meta.key,
      urlSmall: file.meta.urlSmall ?? file.meta.cdnUrl ?? "",
      metadata: {
        filename: "name" in file.data ? file.data.name : undefined,
        size: file.data.size,
        mimetype: file.data.type,
      },
      path: file.meta.key,
      fullPath,
    });
  };

  if (bucket === "schoolVerification") {
    return defaultDescriptor(file.uploadURL);
  }

  switch (fileType) {
    case "image":
      return processPhoto(file);
    case "video":
      return processVideo(file);
    default:
      return defaultDescriptor(
        file.meta.cdnUrl ?? `${cdnUrls.file}/${file.meta.key}`
      );
  }
};
