// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconBookOpen = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.8894 4.1738C21.6281 8.34005 21.6619 12.6038 20.9831 16.785C20.8894 17.37 20.3419 17.7675 19.7569 17.685L19.6706 17.6738C17.1094 17.3025 14.5931 18.4725 13.2131 20.6213C13.2131 20.6213 13.2131 20.6213 13.2131 20.625C13.0894 20.82 12.7856 20.7338 12.7856 20.5013V7.19255C12.7856 7.01255 12.8344 6.8363 12.9244 6.68255C14.3569 4.23005 17.1619 2.8988 19.9856 3.3563L20.1994 3.39005C20.5594 3.50255 20.8256 3.8063 20.8894 4.17755V4.1738ZM3.11064 4.1738C2.37189 8.34005 2.33814 12.6038 3.01689 16.785C3.11064 17.37 3.65814 17.7675 4.24314 17.685L4.32939 17.6738C6.87939 17.3063 9.38814 18.4613 10.7719 20.595C10.7756 20.6025 10.7831 20.6138 10.7906 20.6213C10.9144 20.82 11.2181 20.73 11.2181 20.5013V14.1563V7.1963C11.2181 7.0163 11.1694 6.84005 11.0794 6.6863C9.64689 4.2338 6.84189 2.90255 4.01814 3.36005L3.80439 3.3938C3.44439 3.5063 3.17814 3.81005 3.11439 4.1813L3.11064 4.1738Z" /></svg>;
};