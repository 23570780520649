import { createContext, useContext } from "react";

export type TranslationObject = {
  str: `dojo.${string}:${string}`;
  fallback: string;
  subs?: Record<string, string | number>;
};

export type Translator = (translation: TranslationObject) => string;

export const TRANSLATOR_CONTEXT = createContext<Translator>(() => "");
export const useTranslate = () => useContext(TRANSLATOR_CONTEXT);
