// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPencil = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.39186 18.5956C12.6094 15.5056 15.5231 12.1193 18.0881 8.47432C18.1856 8.33557 18.1744 8.14433 18.0619 8.01683C17.6156 7.50308 17.0756 6.97057 16.4381 6.43432C15.8006 5.89807 15.1819 5.46308 14.5969 5.11058C14.4506 5.02058 14.2631 5.04307 14.1431 5.16682C11.0006 8.32807 8.16936 11.7856 5.68686 15.4906C5.60061 15.6218 5.61189 15.7943 5.71689 15.9106C6.14814 16.3906 6.65812 16.8893 7.25062 17.3881C7.84687 17.8868 8.42436 18.3031 8.97186 18.6406C9.10686 18.7231 9.27936 18.7043 9.39186 18.5956Z" /><path d="M6.52686 18.2468C5.99061 17.7968 5.51814 17.3543 5.10564 16.9193C5.00439 16.8106 4.82435 16.8593 4.78685 17.0018L4.71938 17.2718C4.44188 18.3931 4.29561 19.5406 4.27686 20.6956C4.27686 20.8156 4.38189 20.9056 4.49814 20.8831C5.61939 20.6693 6.71061 20.3318 7.75311 19.8706C7.79061 19.8518 7.91812 19.7918 8.04562 19.7318C8.17687 19.6681 8.1881 19.4881 8.0681 19.4093C7.57685 19.0831 7.06311 18.6968 6.52686 18.2468Z" /><path d="M17.1618 5.57183C17.7093 6.02933 18.1856 6.48307 18.6019 6.92182C18.7669 7.09432 19.0481 7.06809 19.1831 6.86934C19.3443 6.62934 19.5056 6.38932 19.6631 6.14932C19.7531 6.01057 19.7419 5.82682 19.6331 5.70307C18.7706 4.69432 17.7506 3.83934 16.6068 3.16434C16.4643 3.08184 16.2844 3.10059 16.1606 3.21309C15.9506 3.40809 15.7406 3.61058 15.5344 3.80933C15.3619 3.97433 15.3844 4.25932 15.5831 4.39057C16.0894 4.72432 16.6181 5.11433 17.1618 5.57183Z" /></svg>;
};