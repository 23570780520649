// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconUndo = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12.0833 21.6638C12.0533 21.6638 12.0233 21.6638 11.997 21.6638C9.957 21.6638 8.02952 20.8801 6.57077 19.4476C5.08952 17.9963 4.25702 16.0576 4.23452 13.9801C4.23077 13.4626 4.64326 13.0388 5.16076 13.0313C5.66701 13.0351 6.10202 13.4401 6.10952 13.9576C6.12827 15.5326 6.75451 17.0026 7.87951 18.1051C9.00451 19.2038 10.5008 19.8151 12.0645 19.7851C13.6395 19.7663 15.1095 19.1401 16.212 18.0151C17.3108 16.8901 17.907 15.4051 17.892 13.8301C17.8583 10.6576 15.3083 8.09259 12.1658 8.00634C12.1508 8.00634 12.0795 8.01009 11.982 8.01009C11.6858 8.02134 11.532 8.37385 11.7308 8.59885L13.6058 10.7063C13.9508 11.0926 13.917 11.6851 13.527 12.0301C13.347 12.1876 13.1258 12.2663 12.9045 12.2663C12.6458 12.2663 12.387 12.1613 12.2033 11.9513L8.70829 8.02134C8.40829 7.68384 8.39326 7.17385 8.67076 6.8176L11.922 2.68509C12.2408 2.27634 12.8333 2.20884 13.2383 2.52759C13.6433 2.84634 13.7145 3.43885 13.3958 3.84385L12.0458 5.56134C11.8658 5.79009 12.0233 6.12384 12.312 6.13134H12.3158C16.4145 6.29634 19.722 9.65635 19.767 13.8038C19.812 18.0938 16.3658 21.6151 12.0833 21.6638Z" /></svg>;
};