// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconChevronDown = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19.9791 7.34371C19.5253 7.30246 19.0753 7.44872 18.7416 7.74122L12.1341 13.505C12.0516 13.5762 11.9278 13.5762 11.8491 13.505L5.17783 7.68496C4.85158 7.39996 4.42033 7.26871 3.99283 7.31746C3.56533 7.36996 3.17534 7.59871 2.92409 7.95496C2.47784 8.58496 2.60908 9.49996 3.22033 10.0325L10.0041 15.95C10.5703 16.445 11.2828 16.6925 11.9916 16.6925C12.7003 16.6925 13.4128 16.445 13.9791 15.95L20.8153 9.98746C21.1491 9.69871 21.3403 9.27871 21.3403 8.83621C21.3403 8.49496 21.2241 8.15746 21.0103 7.88746C20.7628 7.57621 20.3953 7.38121 19.9791 7.34371Z" /></svg>;
};