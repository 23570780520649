// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconChevronUp = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.7795 13.9688L13.9958 8.05128C12.8633 7.06128 11.1533 7.06128 10.0208 8.05128L3.18454 14.0138C2.85079 14.3025 2.65955 14.7225 2.65955 15.165C2.65955 15.5063 2.7758 15.8438 2.98955 16.1138C3.23705 16.425 3.60455 16.62 4.0208 16.6575C4.46705 16.6988 4.92079 16.5525 5.25829 16.26L11.8658 10.4963C11.9445 10.425 12.0683 10.425 12.1508 10.4963L18.822 16.3163C19.1033 16.56 19.4595 16.6913 19.827 16.6913C19.887 16.6913 19.947 16.6913 20.007 16.68C20.4345 16.6275 20.8245 16.3988 21.0758 16.0425C21.522 15.4125 21.3908 14.5013 20.7795 13.965V13.9688Z" /></svg>;
};