import { type FC, type MutableRefObject, useRef } from "react";
import { useDeleteFile } from "../../state";
import { useMediaThumbnail } from "./hooks";
import LOADING from "./loading-mojo.gif";
import styles from "./MediaThumbnail.module.css";

import MinusButton from "./MinusButton";

type Props = {
  file: File;
};

const MediaThumbnail: FC<Props> = ({ file }) => {
  const deleteFile = useDeleteFile(file);
  const element = useRef<HTMLImageElement>(
    null,
  ) as MutableRefObject<HTMLImageElement>;
  useMediaThumbnail(file, element);

  return (
    <picture className={styles.container}>
      <img
        className={styles.img}
        src={LOADING}
        ref={element}
        alt={file.name}
        width="40%"
        height="40%"
      />
      <MinusButton className={styles.xButton} onClick={deleteFile} />
    </picture>
  );
};

export default MediaThumbnail;
