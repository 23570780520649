// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconCheck = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.29629 20.8912C8.85754 20.8912 8.43754 20.7 8.14879 20.3587L3.44254 14.7787C2.98129 14.2312 2.93254 13.4212 3.37504 12.8625C3.98629 12.09 5.10754 12.1012 5.70754 12.8137L8.65504 16.305C8.82379 16.5037 9.13879 16.4775 9.26629 16.2487C10.7625 13.5975 13.5338 9.11246 17.625 4.13246C18.0788 3.58121 18.87 3.38996 19.5 3.72371C20.3663 4.18496 20.5575 5.29121 19.965 6.00746C13.815 13.485 10.68 19.9687 10.6463 20.0362C10.425 20.4937 9.99004 20.8125 9.48379 20.8762C9.42004 20.8837 9.35629 20.8875 9.29254 20.8875L9.29629 20.8912Z" /></svg>;
};