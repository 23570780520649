// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconChevronRight = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.68496 18.8212C7.39996 19.1474 7.26871 19.5787 7.31746 20.0062C7.36621 20.4337 7.59872 20.8237 7.95497 21.0749C8.20622 21.2549 8.50246 21.3412 8.80246 21.3412C9.25246 21.3412 9.70997 21.1462 10.0325 20.7787L15.95 13.9949C16.94 12.8624 16.94 11.1524 15.95 10.0199L9.98746 3.18369C9.69871 2.84994 9.27871 2.65869 8.83621 2.65869C8.49496 2.65869 8.15747 2.77494 7.88747 2.98869C7.57622 3.23619 7.38121 3.60369 7.34371 4.01994C7.30246 4.46994 7.44872 4.91994 7.74122 5.25744L13.505 11.8649C13.5762 11.9474 13.5762 12.0712 13.505 12.1499L7.68496 18.8212Z" /></svg>;
};