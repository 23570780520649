// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconTrash = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.8105 20.7041C13.2885 21.1445 10.7114 21.1445 8.1894 20.7041C7.73522 20.6249 7.37051 20.2912 7.24665 19.8508C6.33142 16.5511 6.10434 13.1001 6.57227 9.71105L6.60668 9.46676C6.6342 9.27064 6.8028 9.12613 6.99892 9.12613H17.001C17.2006 9.12613 17.3657 9.27064 17.3932 9.46676L17.4276 9.71105C17.899 13.1001 17.6685 16.5511 16.7533 19.8508C16.6294 20.2946 16.2647 20.6249 15.8105 20.7041ZM20.1871 6.63163L20.1492 6.53529C20.0185 6.21187 19.726 5.97446 19.382 5.92285C18.0642 5.72329 16.7361 5.58566 15.408 5.4962C15.2428 5.48588 15.1017 5.37234 15.0536 5.21063L14.6889 3.92725C14.565 3.49372 14.2003 3.17374 13.7564 3.10492C12.5935 2.91913 11.4064 2.91913 10.2435 3.10492C9.79964 3.17718 9.43493 3.49716 9.31106 3.92725L8.94635 5.21063C8.90162 5.37234 8.76055 5.48588 8.59196 5.4962C7.26385 5.58566 5.93918 5.72329 4.61796 5.92285C4.27045 5.97446 3.98143 6.20843 3.85069 6.53529L3.81284 6.63163C3.71994 6.86904 3.71994 7.13053 3.81284 7.36794C3.96423 7.74297 4.32894 7.9907 4.73494 7.9907H19.265C19.671 7.9907 20.0357 7.74297 20.1871 7.36794C20.28 7.13053 20.28 6.86904 20.1871 6.63163Z" /></svg>;
};