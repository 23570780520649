export const FILES_ALLOWED_TYPES = [
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".txt",
  ".rtf",
  ".csv",
];
export const IMAGES_ALLOWED_TYPES = [
  ".heic",
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".tiff",
];
export const VIDEO_FILE_ALLOWED_TYPES = [".mp4"];
export const VIDEO_RECORDING_ALLOWED_TYPES = [".webm"];
export const VIDEO_ALLOWED_TYPES = [
  ...VIDEO_FILE_ALLOWED_TYPES,
  ...VIDEO_RECORDING_ALLOWED_TYPES,
];
export const MEDIA_ALLOWED_TYPES = [
  ...IMAGES_ALLOWED_TYPES,
  ...VIDEO_ALLOWED_TYPES,
];
export const ALL_ALLOWED_TYPES = [
  ...MEDIA_ALLOWED_TYPES,
  ...FILES_ALLOWED_TYPES
]
