// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMagic = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.27623 5.97374L6.68998 5.11124L7.55248 3.52499C7.79998 3.07124 7.63123 2.50124 7.17748 2.25374C6.71998 2.00624 6.15373 2.17499 5.90623 2.62874L5.04373 4.21499L3.45748 3.35249C2.99998 3.10499 2.43373 3.27374 2.18623 3.72749C1.93873 4.18124 2.10748 4.75124 2.56123 4.99874L4.14748 5.86124L3.28498 7.44749C3.03748 7.90124 3.20623 8.47124 3.65998 8.71874C3.80248 8.79749 3.95623 8.83124 4.10623 8.83124C4.43998 8.83124 4.76248 8.65499 4.93123 8.33999L5.79373 6.75374L7.37998 7.61624C7.52248 7.69499 7.67623 7.72874 7.82623 7.72874C8.15998 7.72874 8.48248 7.55249 8.65123 7.23749C8.89873 6.78374 8.72998 6.21374 8.27623 5.96624V5.97374ZM8.0062 20.5987L7.07245 16.3012C6.9562 15.765 6.3037 15.5587 5.8987 15.9262L2.64745 18.885C2.24245 19.2525 2.3887 19.92 2.90995 20.0887L7.0987 21.4275C7.61995 21.5925 8.1262 21.135 8.00995 20.5987H8.0062ZM18.0151 16.4738L20.4938 17.46H20.4976C21.3526 17.7975 22.1101 16.8 21.5513 16.0688L19.9276 13.95C19.2038 13.005 19.2076 11.6925 19.9388 10.755L21.7126 8.475C22.2788 7.74375 21.5138 6.73125 20.6551 7.08L18.2926 8.04375C17.1226 8.52 15.7801 8.0925 15.1013 7.0275L13.5376 4.57875C13.0351 3.7875 11.8088 4.18875 11.8726 5.12625L12.0563 7.82625C12.1463 9.1275 11.2613 10.2975 9.98255 10.5638L7.33505 11.1188C6.4163 11.31 6.3638 12.6 7.26005 12.87L10.0426 13.71C11.2538 14.0738 12.0301 15.2513 11.8876 16.5075L11.6026 19.0425C11.4976 19.9613 12.6788 20.4263 13.2301 19.68L14.9438 17.3513C15.6488 16.395 16.9088 16.035 18.0151 16.4738Z" /></svg>;
};