// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconList = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.0644 12.9356H8.63445C8.11695 12.9356 7.69695 12.5156 7.69695 11.9981C7.69695 11.4806 8.11695 11.0606 8.63445 11.0606H20.0644C20.5819 11.0606 21.0019 11.4806 21.0019 11.9981C21.0019 12.5156 20.5819 12.9356 20.0644 12.9356Z" /><path d="M19.9294 7.53564H8.58568C8.06818 7.53564 7.64818 7.11564 7.64818 6.59814C7.64818 6.08064 8.06818 5.66064 8.58568 5.66064H19.9294C20.4469 5.66064 20.8669 6.08064 20.8669 6.59814C20.8669 7.11564 20.4469 7.53564 19.9294 7.53564Z" /><path d="M19.9294 18.3394H8.58568C8.06818 18.3394 7.64818 17.9194 7.64818 17.4019C7.64818 16.8844 8.06818 16.4644 8.58568 16.4644H19.9294C20.4469 16.4644 20.8669 16.8844 20.8669 17.4019C20.8669 17.9194 20.4469 18.3394 19.9294 18.3394Z" /><path d="M4.68567 8.28564C5.61765 8.28564 6.37317 7.53013 6.37317 6.59814C6.37317 5.66616 5.61765 4.91064 4.68567 4.91064C3.75369 4.91064 2.99817 5.66616 2.99817 6.59814C2.99817 7.53013 3.75369 8.28564 4.68567 8.28564Z" /><path d="M4.68567 13.6856C5.61765 13.6856 6.37317 12.9301 6.37317 11.9981C6.37317 11.0662 5.61765 10.3106 4.68567 10.3106C3.75369 10.3106 2.99817 11.0662 2.99817 11.9981C2.99817 12.9301 3.75369 13.6856 4.68567 13.6856Z" /><path d="M4.68567 19.0894C5.61765 19.0894 6.37317 18.3339 6.37317 17.4019C6.37317 16.4699 5.61765 15.7144 4.68567 15.7144C3.75369 15.7144 2.99817 16.4699 2.99817 17.4019C2.99817 18.3339 3.75369 19.0894 4.68567 19.0894Z" /></svg>;
};