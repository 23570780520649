// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconUser = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 12.8999C9.16873 12.8999 6.86621 10.5974 6.86621 7.7662C6.86621 4.93495 9.16873 2.63245 12 2.63245C14.8312 2.63245 17.1337 4.93495 17.1337 7.7662C17.1337 10.5937 14.8312 12.8999 12 12.8999Z" /><path d="M12 14.5912C10.4625 14.5912 9.04497 14.0812 7.90497 13.2187C6.80247 12.3862 5.21624 13.1549 5.20874 14.5387C5.20874 14.5499 5.20874 14.5649 5.20874 14.5762C5.20874 18.3262 8.24998 21.3674 12 21.3674C15.75 21.3674 18.7912 18.3262 18.7912 14.5762C18.7912 14.5649 18.7912 14.5499 18.7912 14.5387C18.7837 13.1549 17.1975 12.3862 16.095 13.2187C14.955 14.0812 13.5375 14.5912 12 14.5912Z" /></svg>;
};