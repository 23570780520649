// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconAttach = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.2949 22.1115C11.2312 22.1115 11.1674 22.1115 11.1074 22.1078C8.66245 22.0065 6.67121 20.064 6.47621 17.5928C6.16121 12.789 6.35246 8.58903 7.04246 5.09403V4.90653L7.06873 4.79403C7.51498 3.00903 9.11245 1.78652 10.8749 1.89527L10.9199 1.89902C12.9637 2.11277 14.46 3.93153 14.2762 5.96028L14.2687 6.27153C13.9725 8.72403 13.8637 11.4053 13.95 14.244C13.95 15.0278 13.6462 15.7365 13.1175 16.2203C12.6187 16.674 11.9775 16.8915 11.3025 16.8315C10.0425 16.704 9.11999 15.7815 8.99249 14.529L8.98873 14.4353V14.1315C8.90623 12.2603 9.08248 10.3028 9.49873 8.46527C9.61498 7.95902 10.1137 7.64402 10.62 7.76027C11.1262 7.87652 11.4412 8.37902 11.325 8.88152C10.9425 10.5615 10.7812 12.354 10.86 14.0678V14.3828C10.9087 14.7278 11.1187 14.9303 11.475 14.964C11.61 14.979 11.7375 14.934 11.8462 14.8365C11.985 14.709 12.0675 14.5028 12.0675 14.274C11.9812 11.394 12.09 8.63403 12.3937 6.10278L12.3975 5.81777C12.5062 4.80152 11.76 3.88654 10.74 3.76654C9.9112 3.72904 9.17246 4.28778 8.91371 5.14653L8.89498 5.36777C8.23498 8.67527 8.0437 12.8528 8.3437 17.454C8.4637 18.969 9.68622 20.169 11.1787 20.229C12.885 20.2965 14.28 19.0478 14.34 17.3978L14.5012 14.379C14.5837 12.7778 15.0637 11.1915 15.9187 9.66903C16.1737 9.21903 16.7437 9.05778 17.1937 9.31278C17.6437 9.56778 17.805 10.1378 17.55 10.5878C16.8375 11.8553 16.44 13.164 16.3725 14.4765L16.2112 17.4803C16.1175 20.0678 13.9274 22.1115 11.2949 22.1115Z" /></svg>;
};