import {
  type DragEvent,
  type FC,
  type MutableRefObject,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useRef,
  ChangeEvent,
} from "react";
import classnames from "classnames";
import { useTranslate } from "../i18n";
import { useFiles, useIsDragging, useOnFilesChange } from "../state";
import {
  mergeFileLists,
  partitionFiles,
  preventDefault,
  useOnPastedFiles,
  useUpdatedDragStateHandler,
} from "./utils";

import XButton from "../XButton";
import Nav from "./Nav";
import styles from "./Dropzone.module.css";

export type Props = PropsWithChildren<{
  allowedFileExtensions: string[];
  className?: string;
  maxAllowedFiles: number;
  prompt: ReactNode;
  onCancel?(): void;
  onInvalidFileDrop?(message: string): void;
}>;

const INPUT_NAME = "classdojo_drop-zone_file-input";

const ERROR_MESSAGE = {
  invalidFile: {
    str: "dojo.common:file_upload.invalid_file_drop",
    fallback: "That file type is unsupported.",
  },
  tooManyFiles: (maxAllowedFiles: number) =>
    ({
      str: "dojo.common:file_upload.too_many_files",
      fallback: "You can add up to __max_allowed_files__ photos / videos.",
      subs: { max_allowed_files: maxAllowedFiles },
    }) as const,
} as const;

const Dropzone: FC<Props> = ({
  allowedFileExtensions,
  children,
  className,
  maxAllowedFiles,
  onCancel,
  onInvalidFileDrop,
  prompt,
}) => {
  const fileInput =
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const [isDragging, updateDragState] = useIsDragging();
  const [files, { addFiles, setFiles }] = useFiles();
  const translate = useTranslate();
  const handleEnter = useUpdatedDragStateHandler("enter", updateDragState);
  const handleLeave = useUpdatedDragStateHandler("leave", updateDragState);
  const allowMultiple = maxAllowedFiles > 1;

  const onFiles = useCallback(
    (filesOrEvent: File[] | ChangeEvent<HTMLInputElement>) => {
      const newFiles = Array.isArray(filesOrEvent)
        ? filesOrEvent
        : Array.from(filesOrEvent.target.files ?? []);
      const { valid, invalid } = partitionFiles(
        newFiles,
        allowedFileExtensions
      );
      if (invalid.length > 0) {
        onInvalidFileDrop?.(translate(ERROR_MESSAGE.invalidFile));
      }

      const allFiles = [...files, ...valid];

      if (allowMultiple && allFiles.length > maxAllowedFiles) {
        onInvalidFileDrop?.(
          translate(ERROR_MESSAGE.tooManyFiles(maxAllowedFiles))
        );
      }

      if (valid.length > 0) {
        if (allowMultiple) {
          addFiles(valid, maxAllowedFiles);
        } else {
          setFiles(valid);
        }
      }
    },
    [
      addFiles,
      setFiles,
      allowedFileExtensions,
      onInvalidFileDrop,
      maxAllowedFiles,
      allowMultiple,
      files,
      translate,
    ]
  );

  useOnPastedFiles(onFiles);

  const handleDrop = useCallback(
    (event: DragEvent<HTMLFormElement>) => {
      event.preventDefault();
      updateDragState("drop");
      onFiles(Array.from(event.dataTransfer?.files ?? []));
    },
    [updateDragState, onFiles]
  );

  // syncronize file input with changes in file state
  useOnFilesChange(
    useCallback(
      (files: File[]) => {
        if (fileInput.current) {
          fileInput.current.files = mergeFileLists(files);
        }
      },
      [fileInput]
    )
  );

  const handleCancel = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.value = "";
      setFiles([]);
    }
    onCancel?.();
  }, [fileInput, setFiles, onCancel]);

  const shouldShowPrompt = files.length === 0;

  return (
    <form
      name={INPUT_NAME}
      onDragEnter={handleEnter}
      onDragLeave={handleLeave}
      onDragOver={preventDefault}
      onDrop={handleDrop}
      className={classnames(
        styles.main,
        { [styles.dragging]: isDragging },
        className
      )}
    >
      {shouldShowPrompt ? (
        <label
          data-name="file_upload.dropzone.prompt"
          className={styles.promptContainer}
          htmlFor={INPUT_NAME}
        >
          {onCancel && (
            <XButton className={styles.cancelButton} onClick={handleCancel} />
          )}
          <div className={styles.prompt}>{prompt}</div>
        </label>
      ) : (
        <div className={styles.children}>
          <Nav
            maxAllowedFiles={maxAllowedFiles}
            onCancel={onCancel ? handleCancel : undefined}
            fileInputName={INPUT_NAME}
          />
          {children}
        </div>
      )}
      <input
        disabled={files.length >= maxAllowedFiles}
        multiple={allowMultiple}
        accept={allowedFileExtensions.join(",")}
        onChange={onFiles as () => void}
        ref={fileInput}
        type="file"
        className={classnames(styles.input, {
          [styles.inputHidden]: !shouldShowPrompt,
        })}
        name={INPUT_NAME}
        id={INPUT_NAME}
      />
    </form>
  );
};

export default Dropzone;
