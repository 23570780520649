// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconCheckBoxFilled = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.6325 6.57371C20.4038 4.90496 19.095 3.59246 17.4262 3.36746C13.8262 2.87621 10.1774 2.87621 6.57742 3.36746C4.90867 3.59621 3.59625 4.90871 3.37125 6.57371C2.88 10.1737 2.88 13.8225 3.37125 17.4225C3.6 19.0912 4.91242 20.4037 6.57742 20.6287C10.1774 21.12 13.8262 21.12 17.4262 20.6287C19.095 20.4 20.4075 19.0875 20.6325 17.4225C21.1238 13.8225 21.1238 10.1737 20.6325 6.57371ZM16.3163 8.98121C13.2338 12.7312 11.6587 15.9825 11.6437 16.0162C11.5049 16.305 11.2312 16.5 10.9162 16.5412C10.8749 16.545 10.8374 16.5487 10.7962 16.5487C10.5224 16.5487 10.26 16.4287 10.08 16.215L7.69501 13.3875C7.36126 12.99 7.40993 12.4012 7.80743 12.0675C8.20493 11.7337 8.79369 11.7825 9.12744 12.18L10.6199 13.95C11.3812 12.6037 12.7875 10.3237 14.8687 7.79246C15.1987 7.39121 15.7875 7.33496 16.1887 7.66496C16.5862 7.99121 16.6425 8.58371 16.3163 8.98121Z" /></svg>;
};