// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconHelpCircle = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 4.5C16.1362 4.5 19.5 7.86375 19.5 12C19.5 16.1362 16.1362 19.5 12 19.5C7.86375 19.5 4.5 16.1362 4.5 12C4.5 7.86375 7.86375 4.5 12 4.5ZM12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM11.07 12.6112C11.355 11.0962 12.7575 11.0437 12.8325 10.0162C12.87 9.495 12.4462 9.045 11.925 9.015C11.46 8.985 11.145 9.195 10.92 9.46875C10.9163 9.4725 10.9125 9.48 10.9088 9.48375C10.5825 9.90375 10.0012 10.0312 9.5475 9.75375C9.075 9.465 8.9025 8.85 9.18375 8.3775C9.2175 8.32125 9.25125 8.265 9.2925 8.20875C9.88125 7.36875 10.8488 6.80625 12.1237 6.80625C13.5975 6.80625 14.8762 7.63875 14.8762 9.48375C14.8762 11.1638 13.5863 11.6213 13.1213 12.7762C13.0688 12.9037 13.035 13.05 13.0087 13.1962C12.93 13.6763 12.5138 14.0287 12.0262 14.0287C11.4375 14.0287 10.9838 13.5262 11.0287 12.9412C11.0363 12.8287 11.0513 12.72 11.07 12.615V12.6112ZM12.03 14.8612C12.7725 14.8612 13.3538 15.4725 13.3538 16.215C13.3538 16.9575 12.7725 17.5387 12.03 17.5387C11.2875 17.5387 10.7062 16.9425 10.7062 16.215C10.7062 15.4875 11.2875 14.8612 12.03 14.8612Z" /></svg>;
};