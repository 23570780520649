export abstract class FileUploadError extends Error {
  public handled: boolean;
}

export class S3UploadError extends FileUploadError {
  public handled = true;

  constructor(cause: unknown) {
    super("[FileUploadError] Error uploading files to S3", { cause });
  }
}

export class UppyUploadError extends FileUploadError {
  public handled = true;

  constructor(cause: unknown) {
    super("[FileUploadError] Error uploading files with Uppy", { cause });
  }
}

export class MediaDescriptionGeneratorError extends FileUploadError {
  public handled = true;

  constructor(cause: unknown) {
    super("[FileUploadError] Error generating media description", { cause });
  }
}

export class Mp4FileCheckerError extends FileUploadError {
  public handled = true;

  constructor(cause: unknown) {
    super("[FileUploadError] Error running mp4filechecker", { cause });
  }
}
