// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconChevronLeft = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.315 5.1779C16.6 4.85165 16.7312 4.4204 16.6825 3.9929C16.63 3.5654 16.4012 3.1754 16.045 2.92415C15.415 2.4779 14.5037 2.60915 13.9675 3.2204L8.05 10.0041C7.06 11.1366 7.06 12.8466 8.05 13.9791L14.0125 20.8154C14.3012 21.1492 14.7212 21.3404 15.1637 21.3404C15.5087 21.3404 15.8425 21.2241 16.1125 21.0104C16.4237 20.7629 16.6187 20.3954 16.6562 19.9791C16.6975 19.5291 16.5512 19.0792 16.2587 18.7417L10.495 12.1342C10.4237 12.0517 10.4237 11.9317 10.495 11.8492L16.315 5.1779Z" /></svg>;
};